import React, { useState, useEffect } from 'react';
import {
  useMediaQuery,
  useTheme,
  Box,
  Button,
  Typography,
  Breadcrumbs,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Snackbar,
  Alert
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from './sidebar';
import makeApiRequest from '../utils/api';
import useStyles from './styles';
import './tools-styles.css';
import ToolModal from './ToolModal';
import Search from './search';
import DataTable from './commonTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { getColumns } from './columns';

const Tools = () => {

  const toolsColumns = getColumns('tools');

  const toolsActions = [
    {
      label: 'Edit',
      icon: <EditIcon />,
      handler: (item) => openModal(item),
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      handler: (item) => openDeleteConfirmation(item.id),
    },
  ];

  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tools, setTools] = useState([]);
  const [filteredTools, setFilteredTools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({
    open: false,
    tool: null,
    isEditing: false
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    toolId: null,
  });

  useEffect(() => {
    fetchTools();
  }, []);

  const fetchTools = async () => {
    setLoading(true);
    try {
      const response = await makeApiRequest('api/tools', 'GET');
      if (response && Array.isArray(response.tools)) {
        setTools(response.tools);
        setFilteredTools(response.tools);
      }
    } catch (error) {
      console.error('Error fetching tools:', error);
      showSnackbar('Failed to fetch tools', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleToolsClick = () => navigate(`/tools`);

  const handleAddEditTool = async (toolData) => {
    setLoading(true);
    const method = modalState.isEditing ? 'PUT' : 'POST';
    const url = modalState.isEditing ? `api/tools/${modalState.tool.id}` : 'api/tools';
    try {
      const response = await makeApiRequest(url, method, {}, toolData);
      if (modalState.isEditing) {
        setTools((prevTools) =>
          prevTools.map((tool) => (tool.id === response.tool.id ? response.tool : tool))
        );
      } else {
        setTools((prevTools) => [response.tool, ...prevTools]);
      }
      fetchTools()
      closeModal();
      showSnackbar(`Tool ${modalState.isEditing ? 'updated' : 'added'} successfully!`);
    } catch (error) {
      console.error(`Error ${modalState.isEditing ? 'editing' : 'adding'} tool:`, error);
      showSnackbar(`Failed to ${modalState.isEditing ? 'edit' : 'add'} tool`, 'error');
    } finally {
      setLoading(false);
    }
  };


  const handleDeleteTool = async (toolId) => {
    setLoading(true);
    try {
      await makeApiRequest(`api/tools/${toolId}`, 'DELETE');
      fetchTools();
      showSnackbar('Tool deleted successfully!');
    } catch (error) {
      console.error('Error deleting tool:', error);
      showSnackbar('Failed to delete tool', 'error');
    } finally {
      setLoading(false);
    }
  };
  const openModal = (tool = null) => {
    setModalState({
      open: true,
      tool,
      isEditing: !!tool
    });
  };
  const closeModal = () => {
    setModalState({
      open: false,
      tool: null,
      isEditing: false
    });
  };
  const showSnackbar = (message, severity = 'success') => {
    setSnackbarState({
      open: true,
      message,
      severity
    });
  };

  const openDeleteConfirmation = (toolId) => {
    setDeleteConfirmation({ open: true, toolId });
  };

  return (
    <Box className={classes.root} sx={{ display: 'flex' }}>
      <Sidebar />
      <Box className={classes.content} sx={{ margin: "20px", flexGrow: 1, padding: '16px' }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="/"
          sx={{
            marginBottom: isMobile ? "0px" : "10px",
            marginTop: isMobile ? "20px" : "0px"
          }}
        >
          <Typography className="home-icons-container-styles" color="textPrimary">
            <HomeIcon className='home-icon-styles' fontSize="inherit" />
            Home
          </Typography>
          <Typography>Tools</Typography>
        </Breadcrumbs>
        <DataTable
          item="Tool"
          openModal={openModal}
          handleClick={handleToolsClick}
          data={filteredTools}
          loading={loading}
          columns={toolsColumns}
          actions={toolsActions}
        />
        <ToolModal
          open={modalState.open}
          onClose={closeModal}
          onSubmit={handleAddEditTool}
          toolData={modalState.tool}
          isEditing={modalState.isEditing}
        />
        <Snackbar
          open={snackbarState.open}
          autoHideDuration={6000}
          onClose={() => setSnackbarState(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarState(prev => ({ ...prev, open: false }))}
            severity={snackbarState.severity}
          >
            {snackbarState.message}
          </Alert>
        </Snackbar>
        <Dialog
          open={deleteConfirmation.open}
          onClose={() => setDeleteConfirmation({ open: false, toolId: null })}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this tool?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmation({ open: false, toolId: null })}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteTool(deleteConfirmation.toolId);
                setDeleteConfirmation({ open: false, toolId: null });
              }}
              color="error"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};
export default Tools;