import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Typography, 
  Paper,
  Avatar,
  IconButton,
  useTheme
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import threadMessagesData from './thread_message.json';

const ThreadMessages = ({ threadId, threadStatus }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const theme = useTheme();
  console.log(threadStatus)
  useEffect(() => {
    fetchMessages();
  }, [threadId]);

  const fetchMessages = () => {
    const filteredMessages = threadMessagesData.messages.filter(
      message => message.threadId === threadId
    );
    setMessages(filteredMessages);
  };

  const sendMessage = () => {
    if (!newMessage.trim() || threadStatus === 'closed') return;

    const newMessageObj = {
      id: messages.length + 1,
      threadId: threadId,
      content: newMessage,
      sender: 'Current User',
      timestamp: new Date().toISOString()
    };

    setMessages([...messages, newMessageObj]);
    setNewMessage('');
  };

  return (
    <Paper 
      elevation={3}
      sx={{
        height: '600px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        overflow: 'hidden'
      }}
    >
      {/* Messages Container */}
      <Box 
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          p: 2,
          backgroundColor: theme.palette.grey[100]
        }}
      >
        {messages.map((message, index) => {
          const isRight = index % 2 === 0;
          return (
            <Box
              key={message.id}
              sx={{
                display: 'flex',
                justifyContent: isRight ? 'flex-end' : 'flex-start',
                mb: 2
              }}
            >
              {!isRight && (
                <Avatar
                  sx={{
                    bgcolor: isRight ? theme.palette.secondary.main : theme.palette.primary.main,
                    width: 40,
                    height: 40,
                    mr: 1,
                    boxShadow: theme.shadows[2]
                  }}
                >
                  {message.sender.charAt(0)}
                </Avatar>
              )}
              <Box sx={{ maxWidth: '70%' }}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    backgroundColor: isRight ? theme.palette.primary.main : '#ffffff',
                    color: isRight ? 'black' : 'inherit',
                    borderRadius: 2,
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 10,
                      [isRight ? 'right' : 'left']: -10,
                      borderStyle: 'solid',
                      borderWidth: '10px 10px 0',
                      borderColor: `${isRight ? theme.palette.primary.main : '#ffffff'} transparent transparent`,
                      transform: isRight ? 'rotate(-45deg)' : 'rotate(45deg)'
                    }
                  }}
                >
                  <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                    {message.content}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      display: 'block',
                      mt: 1,
                      opacity: 0.8
                    }}
                  >
                    {new Date(message.timestamp).toLocaleTimeString()}
                  </Typography>
                </Paper>
              </Box>
              {isRight && (
                <Avatar
                  sx={{
                    bgcolor: isRight ? theme.palette.secondary.main : theme.palette.primary.main,
                    width: 40,
                    height: 40,
                    ml: 1,
                    boxShadow: theme.shadows[2]
                  }}
                >
                  {message.sender.charAt(0)}
                </Avatar>
              )}
            </Box>
          );
        })}
      </Box>

      {/* Input Area */}
      <Box 
        sx={{ 
          p: 2, 
          backgroundColor: '#ffffff',
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={threadStatus === 'closed' ? "This thread is closed" : "Type your message..."}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            disabled={threadStatus === 'closed'}
            sx={{
              mr: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: 3
              }
            }}
          />
          <IconButton 
            color="primary" 
            onClick={sendMessage}
            disabled={threadStatus === 'closed'}
            sx={{
              width: 48,
              height: 48,
              backgroundColor: theme.palette.primary.main,
              color: '#ffffff',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.grey[100]
              }
            }}
          >
            <SendIcon />
          </IconButton>
        </Box>
        {threadStatus === 'closed' && (
          <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
            This thread is closed. You cannot send new messages.
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ThreadMessages;