// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
// import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, useTheme, useMediaQuery, Breadcrumbs } from '@mui/material';
// import Sidebar from './sidebar';
// import makeApiRequest from '../utils/api';
// import './articles.css';
// import Search from './search';
// import ArticlesTable from './articlesTable';
// import HomeIcon from '@mui/icons-material/Home';
// import { Link as RouterLink } from 'react-router-dom';

// const Articles = () => {
//   console.log('Articles component rendering');

//   const theme = useTheme();
//   const { topicId } = useParams();
//   const navigate = useNavigate();
//   const [articles, setArticles] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [articleToDelete, setArticleToDelete] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const location = useLocation();
//   console.log(location)
//   const subjectId = location.state?.subjectId || '';
//   const subjectName = location.state?.subjectName || 'Subject Name';
//   const topicName = location.state?.topicName || 'Topic Name';


//   console.log('Current topicId:', topicId);

//   useEffect(() => {
//     console.log('useEffect triggered with topicId:', topicId);
//     fetchData();
//   }, [topicId]);

//   const fetchData = async () => {
//     console.log('Fetching data for topicId:', topicId);
//     setLoading(true);
//     try {
//       const response = await makeApiRequest(`api/articles/topic/${topicId}`);
//       console.log('API Response:', response);

//       const articlesWithVersion = response.map(article => {
//         const version = article.version ? String(article.version) : "1";
//         console.log(`Processing article ${article.id}, version: ${version}`);
//         return {
//           ...article,
//           version,
//           status: article.status || "DRAFTED",
//         };
//       });

//       console.log('Processed Articles:', articlesWithVersion);
//       setArticles(articlesWithVersion);
//     } catch (error) {
//       console.error('Error fetching articles:', error);
//       setArticles([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAddArticle = () => {
//     console.log('Adding new article');
//     navigate(`/markdown-preview/${topicId}`);
//   };

//   const handleEditClick = async (article) => {
//     console.log('Handling edit click for article:', article);

//     if (article.status === "DRAFTED") {
//       try {
//         console.log(`Creating new version for article ${article.id}, current version: ${article.version}`);
//         const response = await makeApiRequest(`api/articles/${article.id}/version`, 'POST');
//         console.log('Version update response:', response);

//         if (response) {
//           const currentVersion = parseInt(article.version) || 1;
//           const newVersion = currentVersion + 1;

//           setArticles(prevArticles => {
//             const updatedArticles = prevArticles.map(a =>
//               a.id === article.id
//                 ? { ...a, version: String(newVersion) }
//                 : a
//             );
//             console.log('Updated articles state:', updatedArticles);
//             return updatedArticles;
//           });

//           navigate(`/markdown-preview/${topicId}/${article.id}`);
//           setTimeout(() => {
//             console.log('Fetching fresh data after edit');
//             fetchData();
//           }, 500);
//         }
//       } catch (error) {
//         console.error('Failed to create new version:', error);
//         setSnackbarMessage('Failed to create new version');
//         setSnackbarOpen(true);
//       }
//     } else {
//       console.log('Article is published, navigating without version update');
//       navigate(`/markdown-preview/${topicId}/${article.id}`);
//     }
//   };

//   const handleDeleteArticle = (id) => {
//     console.log('Preparing to delete article:', id);
//     setArticleToDelete(id);
//     setOpenConfirmDialog(true);
//   };

//   const confirmDeleteArticle = async () => {
//     console.log('Confirming delete for article:', articleToDelete);
//     try {
//       await makeApiRequest(`api/articles/${articleToDelete}`, 'DELETE');
//       setSnackbarMessage('Article deleted successfully');
//       await fetchData();
//     } catch (error) {
//       console.error('Failed to delete article:', error);
//       setSnackbarMessage('Failed to delete article');
//     }
//     setOpenConfirmDialog(false);
//     setSnackbarOpen(true);
//   };

//   const publishArticle = async (id) => {
//     console.log('Publishing article:', id);
//     try {
//       const response = await makeApiRequest(`api/articles/${id}/publish`, 'POST');
//       console.log('Publish response:', response);
//       setSnackbarMessage('Article published successfully');
//       await fetchData();
//     } catch (error) {
//       console.error('Failed to publish article:', error);
//       setSnackbarMessage('Failed to publish article');
//     }
//     setSnackbarOpen(true);
//   };

//   const handleSearch = (filteredArticles) => {
//     console.log('Search results:', filteredArticles);
//     setArticles(filteredArticles);
//   };

//   console.log('Current articles state:', articles);

//   return (
//     <Box className="article-main-container">
//       <Sidebar />
//       <Box className="article-container-child">
//         <Breadcrumbs aria-label="breadcrumb" separator="/">
//           <Link
//             component={RouterLink}
//             to="/"
//             color="inherit"
//           >
//             <HomeIcon fontSize="small" sx={{ mr: 0.5 }} />
//             Home
//           </Link>
//           <Link
//             component={RouterLink}
//             to="/subjects"
//             color="inherit"
//           >
//             Subjects
//           </Link>
//           <Link
//             component={RouterLink}
//             to={`/subjects/${subjectId}`}
//             color="inherit"
//           >
//             {subjectName}
//           </Link>
//           <Typography color="textPrimary">Topics ({topicName})</Typography>
//         </Breadcrumbs>
//         <Box sx={{
//           marginTop: isMobile ? "40px" : "0px",
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           marginBottom: '20px'
//         }}>
//           <Typography variant="h5" gutterBottom>Articles</Typography>
//           <Search
//             subjects={articles}
//             setFilteredSubjects={handleSearch}
//           />
//           <Button variant="contained" onClick={handleAddArticle}>Add Article</Button>
//         </Box>

//         <ArticlesTable
//           articles={articles}
//           onEditClick={handleEditClick}
//           onDeleteClick={handleDeleteArticle}
//           onPublishClick={publishArticle}
//         />

//         <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
//           <DialogTitle>Confirm Deletion</DialogTitle>
//           <DialogContent>
//             <Typography>Are you sure you want to delete this article?</Typography>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
//             <Button onClick={confirmDeleteArticle} color="error">Delete</Button>
//           </DialogActions>
//         </Dialog>

//         <Snackbar
//           anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity="success">
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Box>
//   );
// };

// export default Articles;


import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, Breadcrumbs, CircularProgress} from '@mui/material';
import Sidebar from './sidebar';
import makeApiRequest from '../utils/api';
import './articles.css';
import HomeIcon from '@mui/icons-material/Home';
import { getColumns } from './columns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from './commonTable';
import useStyles from './styles';
import './articles.css';

const Articles = () => {
  const articleColumns = getColumns('articles');
  
  const articleActions = [
    { label: 'Edit', icon: <EditIcon />, handler: (item) => handleEditClick(item) },
    { label: 'Delete', icon: <DeleteIcon />, handler: (item) => handleDeleteArticle(item.id) },
    { label: 'Publish', icon: <Button>Publish</Button>, handler: (item) => publishArticle(item.id) },
  ];

  const classes = useStyles();
  const { topicId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [filteredArticles, setFilteredArticles] = useState([])
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const subjectName = location.state?.subjectName || 'Subject Name';
  const topicName = location.state?.topicName || 'Topic Name';

  useEffect(() => {
    fetchData();
  }, [topicId]);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await makeApiRequest(`api/articles/topic/${topicId}`);

      const articlesWithVersion = response.map(article => ({
        ...article,
        version: article.version ? parseInt(article.version, 10) : 1,
        status: article.status || "DRAFTED"
      }));

      setArticles(articlesWithVersion);
      setFilteredArticles(articlesWithVersion);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setArticles([]);
    } finally {
      setLoading(false);
    }
  };

  const handleArticlesClick = () => navigate(`/articles/${topicId}`);

  const handleAddArticle = () => {
    navigate(`/markdown-preview/${topicId}`);
  };

  const handleEditClick = async (article) => {
    setLoading(true);
    try {
      navigate(`/markdown-preview/${topicId}/${article.id}`, {
        state: {
          currentVersion: article.version,
          isEditing: true
        }
      });

      // Then, create a new version in the background
      const response = await makeApiRequest(`api/articles/${article.id}/version`, 'POST');

      if (response && response.version) {
        const newVersion = parseInt(response.version, 10);

        setArticles(prevArticles =>
          prevArticles.map(a =>
            a.id === article.id
              ? {
                ...a,
                previousVersions: [...(a.previousVersions || []),
                {
                  title: a.title,
                  version: a.version,
                  content: a.content
                }
                ],
                version: newVersion,
                status: "DRAFTED"
              }
              : a
          )
        );

        showSnackbar('New version created successfully');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Failed to create new version:', error);
      showSnackbar('Failed to create new version', 'error');
    } finally {
      setLoading(false);
    }
  };

  // const handleEditClick = async (article) => {
  //   setLoading(true);
  //   try {
  //     const response = await makeApiRequest(`api/articles/${article.id}/version`, 'POST');

  //     if (response && response.version) {
  //       const newVersion = parseInt(response.version, 10);

  //       setArticles(prevArticles => 
  //         prevArticles.map(a =>
  //           a.id === article.id
  //             ? { ...a, version: newVersion, status: "DRAFTED" }
  //             : a
  //         )
  //       );

  //       navigate(`/markdown-preview/${topicId}/${article.id}`);
  //       showSnackbar('New version created successfully');

  //       // Refresh data after a short delay
  //       setTimeout(fetchData, 500);
  //     } else {
  //       throw new Error('Invalid response from server');
  //     }
  //   } catch (error) {
  //     console.error('Failed to create new version:', error);
  //     showSnackbar('Failed to create new version', 'error');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDeleteArticle = (id) => {
    setArticleToDelete(id);
    setOpenConfirmDialog(true);
  };

  const confirmDeleteArticle = async () => {
    setLoading(true);
    try {
      await makeApiRequest(`api/articles/${articleToDelete}`, 'DELETE');
      setArticles(articles.filter((article) => article.id !== articleToDelete));
      showSnackbar('Article deleted successfully');
      fetchData()
    } catch (error) {
      console.error('Failed to delete article:', error);
      showSnackbar('Failed to delete article', 'error');
    } finally {
      setOpenConfirmDialog(false);
      setLoading(false);
    }
  };

  const publishArticle = async (id) => {
    setLoading(true);
    try {
      await makeApiRequest(`api/articles/${id}/publish`, 'POST');
      showSnackbar('Article published successfully');
      await fetchData();
    } catch (error) {
      console.error('Failed to publish article:', error);
      showSnackbar('Failed to publish article', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleBackToSubjects = () => {
    navigate(-2);
  }

  const handleBackToTopics = () => {
    navigate(-1);
  }

  const sortedArticles = filteredArticles.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <Box className="article-main-container">
      <Sidebar />
      <Box className={classes.content} sx={{ flexGrow: 1, padding: '16px', margin: "20px" }}>
         <Breadcrumbs separator="/"
          aria-label="breadcrumb">
          <Typography color="textPrimary" onClick={() => navigate('/')}>
            <HomeIcon fontSize="inherit" />Home
          </Typography>
          <Typography
            color="textPrimary"
            onClick={handleBackToSubjects}
            sx={{ cursor: 'pointer' }}>
            Subjects ({subjectName.length > 10 ? `${subjectName.slice(0, 10)}...` : subjectName})
          </Typography>
          <Typography
            color="textPrimary"
            onClick={handleBackToTopics}
            sx={{ cursor: 'pointer' }}>
            Topics ({topicName.length > 10 ? `${topicName.slice(0, 10)}...` : topicName})
          </Typography>
          <Typography>Articles</Typography>
        </Breadcrumbs>
        <DataTable
          item="Article"
          openModal={handleAddArticle}
          handleClick={handleArticlesClick}
          data={sortedArticles}
          loading={false}
          columns={articleColumns}
          actions={articleActions}
        />
        <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this article?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
            <Button
              onClick={confirmDeleteArticle}
              color="error"
              disabled={loading} >
              {loading ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)} >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity} >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Articles;