import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: '2px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  tableContainer: {
    marginBottom: '5px',
    overflowX: 'auto',
  },
  tableHead: {
    height: '40px',
    backgroundColor: theme.palette.custom.tableColor,
    '& .MuiTableCell-head': {
      color: 'white',
    },
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
    '& td': {
      padding: '2px',
      paddingLeft: '10PX',
      fontSize: '15px',
    },
  },
  iconButton: {
    color: theme.palette.custom.tableColor,
  },
  skeletonRow: {
    '& td': {
      padding: '5px', 
      height: '16px',
      animation:'wave'
    },
  },
  asterisk: {
    color: 'red', 
  },
  DialogTitle: {
    backgroundColor: theme.palette.custom.tableColor,
    color: 'white',
    marginBottom: '20px'
  },
  CustomAsterisk: {
    color: 'red',
  },
  defaultSort: {
    color: 'white !important', 
    cursor: 'pointer',
    '&:hover': {
      color: '#90a4ae !important',
    },
  },
  activeSort: {
    backgroundColor: 'red !important', 
    '&:hover': {
      backgroundColor: 'red !important',
      color: '#cddc39 !important', 
    },
    '& .MuiTableSortLabel-icon': {
      backgroundColor: 'red !important', 
    },
  },
  '@global': {
    '.MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#cddc39 !important', 
    },
    '.MuiTableSortLabel-icon': {
      color: '#90a4ae !important',
    },
  },
  nameColumn: {
    width: '15%',  
  },
  descriptionColumn: {
    width: '35%',  
    whiteSpace: 'nowrap', 
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  createdByColumn: {
    width: '10%',
  },
  createdOnColumn: {
    width: '20%',
  },
  actionsColumn: {
    width: '3%', 
  },
  filterInput: {
    padding: '4px', 
    width: '120px', 
    '& .MuiInputBase-input': {
        padding: '4px', 
        fontSize:'15px'
    },
},
}));
export default useStyles;