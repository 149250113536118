import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, TextareaAutosize, FormHelperText } from '@mui/material';
import useStyles from './styles';

const Modal = ({ open, onClose, onSubmit, data, isEditing, isTopic }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [localErrors, setLocalErrors] = useState({
    name: '',
    description: ''
  });

  const descriptionLimit = 2000;

  useEffect(() => {
    if (data && isEditing && open) {
      setName(data.name);
      setDescription(data.description);
      setLocalErrors({ name: '', description: '' });
    } else if (open) {
      setName('');
      setDescription('');
      setLocalErrors({ name: '', description: '' });
    }
  }, [data, isEditing, open]);

  const validateField = (field, value) => {
    let error = '';
    
    if (field === 'name') {
      if (!value.trim()) {
        error = 'Name is required';
      } else if (!/^[a-zA-Z][a-zA-Z\s_]+$/.test(value)) {
        error = 'Must start with a letter and can contain only letters, spaces, and underscores';
      }  else if (value.length > 40) { 
        error = 'Name must be less than or equal to 40 characters';
    }
    } else if (field === 'description') {
      if (!value.trim()) {
        error = 'Description is required';
      } else if (value.length > descriptionLimit) {
        error = `Description cannot exceed ${descriptionLimit} characters`;
      }
    }
    
    return error;
  };

  const validateFields = () => {
    const nameError = validateField('name', name);
    const descriptionError = validateField('description', description);
    
    setLocalErrors({
      name: nameError,
      description: descriptionError
    });
    
    return !nameError && !descriptionError;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit({ name: name.trim(), description: description.trim() });
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    if (localErrors.name) {
      setLocalErrors(prev => ({
        ...prev,
        name: validateField('name', newName)
      }));
    }
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription.length <= descriptionLimit) {
      setDescription(newDescription);
      if (localErrors.description) {
        setLocalErrors(prev => ({
          ...prev,
          description: validateField('description', newDescription)
        }));
      }
    }
  };

  const handleBlur = (field) => {
    setLocalErrors(prev => ({
      ...prev,
      [field]: validateField(field, field === 'name' ? name : description)
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.DialogTitle}>
        {isEditing ? (isTopic ? 'Edit Topic' : 'Edit Subject') : (isTopic ? 'Add Topic' : 'Add Subject')}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          error={Boolean(localErrors.name)}
          helperText={localErrors.name}
          onChange={handleNameChange}
          onBlur={() => handleBlur('name')}
          style={{
            borderRadius: '4px',
            fontFamily: 'inherit',
            fontSize: 16,
          }}
        />
        <TextareaAutosize
          style={{
            width: '100%',
            fontSize: 16,
            padding: '8px',
            marginTop: '16px',
            minHeight: '100px',
            borderRadius: '4px',
            fontFamily: 'inherit'
          }}
          placeholder="Description"
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          onBlur={() => handleBlur('description')}
        />
        {Boolean(localErrors.description) && (
          <FormHelperText error>{localErrors.description}</FormHelperText>
        )}
        <FormHelperText>
          {description.length}/{descriptionLimit} characters
        </FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          {isEditing ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;