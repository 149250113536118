import React from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = ({ step, handleNextStep, handleBackStep, handleSave, hasErrors }) => {
    const navigate = useNavigate();
    
    const goBack = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 2,
                backgroundColor: '#FFFFFF'
            }}
        >
            {step > 1 && (
                <Button
                    variant="contained"
                    color="grey"
                    onClick={handleBackStep}
                    sx={{ marginRight: 1 }}
                >
                    Back
                </Button>
            )}
            <Box sx={{ display: 'flex', gap: 1 }}>
                {step === 1 && (
                    <Button onClick={goBack}>
                        Go Back
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextStep}
                >
                    Next
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleSave}
                    disabled={hasErrors}
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default Footer;