export const VALIDATION_RULES = {
    NAME: {
        pattern: /^[a-zA-Z][a-zA-Z\s_]*$/,
        maxLength: 40,
        messages: {
            required: "Name is required",
            pattern: "Name must start with a letter and can contain only letters and spaces",
            maxLength: "Name must be less than or equal to 40 characters"
        }
    },
    DESCRIPTION: {
        pattern: /^[a-zA-Z][a-zA-Z\s_]*$/,
        maxLength: 2000,
        messages: {
            required: "Description is required",
            pattern: "Description must start with a letter and can contain only letters and spaces",
            maxLength: "Description should not exceed 2000 characters"
        }
    },
    URL: {
        pattern: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
        messages: {
            required: "Url is required",
            pattern: 'Please enter a valid URL starting with http:// or https://'
        }
    },
    EMAIL: {
        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        messages: {
            required: 'Email is required',
            pattern: 'Please enter a valid email address',
            unique: 'This email is already in use' 
        }
    },
    PASSWORD: {
        messages: {
            required: 'Password is required'
        }
    }
}

export const validateField = (fieldName, value, rules = VALIDATION_RULES) => {
    const fieldRules = rules[fieldName];
    if (!fieldRules) return ""
    //Checking for empty field and required field
    if (!value || (typeof value === 'string' && !value.trim())) {
        return fieldRules.messages.required;
    }
    switch (fieldName) {
        case "NAME":
            if (!value.match(fieldRules.pattern)) {
                return fieldRules.messages.pattern;
            }
            if (value.length > fieldRules.maxLength) {
                return fieldRules.messages.maxLength;
            }
            break;
        case 'DESCRIPTION':
            if (!value.match(fieldRules.pattern)) {
                return fieldRules.messages.pattern;
            }
            if (value.length > fieldRules.maxLength) {
                return fieldRules.messages.maxLength;
            }
            break;

        case 'URL':
            if (!value.match(fieldRules.pattern)) {
                return fieldRules.messages.pattern;
            }
            try {
                new URL(value);
            } catch (e) {
                return fieldRules.messages.invalid;
            }
            break;
        case 'EMAIL':
            if (!fieldRules.pattern.test(value)) {
                return fieldRules.messages.pattern;
            }
            break;
        case "PASSWORD":
            break;
    }
    return ""

}

export const validateForm = (formData, requiredFields) => {
    const errors = {}
    let isValid = true;
    requiredFields.forEach(field => {
        const error = validateField(field, formData[field.toLowerCase()])
        if (error) {
            errors[field.toLowerCase()] = error;
            isValid = false;
        }
    })
    return { isValid, errors }
}