// import React, { useState } from 'react';
// import { TableCell, TextField, TableSortLabel } from '@mui/material';
// import useStyles from './styles';

// const FilterComponent = ({ header, order, orderBy, handleSortRequest, onColumnFilter }) => {
//     const classes = useStyles();
//     const [filterValue, setFilterValue] = useState('');
//     const [activeField, setActiveField] = useState(null);

//     const handleFilterChange = (value) => {
//         setFilterValue(value);
//         onColumnFilter(header.id, value);
//         if (value === '') {
//             setActiveField(null);
//         }
//     };

//     const handleDoubleClick = () => {
//         setActiveField(header.id);
//     };

//     return (
//         <TableCell key={header.id} style={{ position: 'relative', width: '150px' }}>
//             {activeField === header.id ? (
//                 <TextField
//                     variant="outlined"
//                     size="small"
//                     placeholder="Filter"
//                     value={filterValue}
//                     onChange={(e) => handleFilterChange(e.target.value)}
//                     className={classes.filterInput}
//                     autoFocus
//                 />
//             ) : (
//                 <TableSortLabel
//                     active={orderBy === header.id}
//                     direction={orderBy === header.id ? order : 'asc'}
//                     onClick={() => handleSortRequest(header.id)}
//                     onDoubleClick={handleDoubleClick}>
//                     {header.label}
//                 </TableSortLabel>
//             )}
//         </TableCell>
//     );
// };

// export default FilterComponent;









// import React, { useState } from 'react';
// import { TableCell, TextField, TableSortLabel, Box } from '@mui/material';
// import useStyles from './styles';

// const FilterComponent = ({ header, order, orderBy, handleSortRequest, onColumnFilter }) => {
//     const classes = useStyles();
//     const [filterValue, setFilterValue] = useState('');
//     const [activeField, setActiveField] = useState(null); 
//     const [dateRange, setDateRange] = useState({ start: '', end: '' });

//     const handleFilterChange = (value) => {
//         setFilterValue(value);
//         onColumnFilter(header.id, value);
//         if (value === '') {
//             setActiveField(null);
//         }
//     };

//     const handleDateChange = (type, value) => {
//         const updatedRange = { ...dateRange, [type]: value };
//         setDateRange(updatedRange);
//         if (updatedRange.start && updatedRange.end) {
//             onColumnFilter(header.id, updatedRange);
//         }
//     };

//     const handleDoubleClick = () => {
//         setActiveField(header.id); 
//     };

//     return (
//         <TableCell key={header.id} style={{ position: 'relative'}}>
//             {header.type === 'date' && activeField === header.id ? (
//                 <Box display="flex" flexDirection="column">
//                     <TextField
//                         type="date"
//                         variant="outlined"
//                         size="small"
//                         value={dateRange.start}
//                         onChange={(e) => handleDateChange('start', e.target.value)}
//                         className={classes.filterInput}
//                         placeholder="Start Date"
//                         autoFocus
//                     />
//                     <TextField
//                         type="date"
//                         variant="outlined"
//                         size="small"
//                         value={dateRange.end}
//                         onChange={(e) => handleDateChange('end', e.target.value)}
//                         className={classes.filterInput}
//                         placeholder="End Date"
//                         sx={{width:'150px'}}
//                     />
//                 </Box>
//             ) : activeField === header.id ? (
//                 <TextField
//                     variant="outlined"
//                     placeholder="Filter"
//                     value={filterValue}
//                     onChange={(e) => handleFilterChange(e.target.value)}
//                     className={classes.filterInput}
//                     autoFocus
//                 />
//             ) : (
//                 <TableSortLabel
//                     active={orderBy === header.id}
//                     direction={orderBy === header.id ? order : 'asc'}
//                     onClick={() => handleSortRequest(header.id)}
//                     onDoubleClick={handleDoubleClick} 
//                 >
//                     {header.label}
//                 </TableSortLabel>
//             )}
//         </TableCell>
//     );
// };

// export default FilterComponent;




import React, { useState } from 'react';
import { TableCell, TextField, TableSortLabel, Box, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import useStyles from './styles';

const FilterComponent = ({ header, order, orderBy, handleSortRequest, onColumnFilter }) => {
    const classes = useStyles();
    const [filterValue, setFilterValue] = useState('');
    const [activeField, setActiveField] = useState(null); 
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    const handleFilterChange = (value) => {
        setFilterValue(value);
        onColumnFilter(header.id, value);
        if (value === '') {
            setActiveField(null);
        }
    };

    const handleDateChange = (type, value) => {
        const updatedRange = { ...dateRange, [type]: value };
        setDateRange(updatedRange);
        if (updatedRange.start && updatedRange.end) {
            onColumnFilter(header.id, updatedRange);
        }
    };

    const handleDoubleClick = () => {
        setActiveField(header.id); 
    };

    const clearFilter = () => {
        setFilterValue('');
        setDateRange({ start: '', end: '' });
        onColumnFilter(header.id, ''); 
        setActiveField(null); 
    };

    return (
        <TableCell key={header.id} style={{ position: 'relative' }}>
            {header.type === 'date' && activeField === header.id ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        value={dateRange.start}
                        onChange={(e) => handleDateChange('start', e.target.value)}
                        className={classes.filterInput}
                        placeholder={`Start ${header.label}`}  
                    />
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        value={dateRange.end}
                        onChange={(e) => handleDateChange('end', e.target.value)}
                        className={classes.filterInput}
                        placeholder={`End ${header.label}`}  
                    />
                    <IconButton onClick={clearFilter} size="small" className={classes.clearIcon}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            ) : activeField === header.id ? (
                <Box display="flex" alignItems="center">
                    <TextField
                        variant="outlined"
                        placeholder={`Filter by ${header.label}`}  
                        value={filterValue}
                        onChange={(e) => handleFilterChange(e.target.value)}
                        className={classes.filterInput}
                        autoFocus
                    />
                    <IconButton onClick={clearFilter} size="small" className={classes.clearIcon}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            ) : (
                <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={() => handleSortRequest(header.id)}
                    onDoubleClick={handleDoubleClick}
                >
                    {header.label}
                </TableSortLabel>
            )}
        </TableCell>
    );
}

export default FilterComponent;