import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tabs, Tab, Box, Switch, FormControlLabel } from '@mui/material';
import ThreadMessages from './ThreadMessages';
import useStyles from './styles';

const ThreadDetailsModal = ({ open, onClose, onSubmit, toolData, isEditing }) => {
  const classes = useStyles();

  const [threadData, setThreadData] = useState({
    title: '',
    description: '',
    status: 'open'
  });
  const [errors, setErrors] = useState({});
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (toolData) {
      setThreadData(prevData => ({
        ...prevData,
        ...toolData
      }));
    } else {
      setThreadData({
        title: '',
        description: '',
        status: 'open'
      });
    }
    setErrors({});
  }, [toolData]);

  const validateField = (name, value) => {
    if (!value.trim()) {
      setErrors(prev => ({ ...prev, [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required` }));
      return false;
    }

    if (name === 'title') {
      const nameRegex = /^[A-Za-z][A-Za-z_ ]*$/;
      if (!nameRegex.test(value)) {
        setErrors(prev => ({ ...prev, [name]: 'Title must start with a letter and contain only letters, spaces, and underscores' }));
        return false;
      }
    }

    setErrors(prev => ({ ...prev, [name]: null }));
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setThreadData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (errors[name]) {
      validateField(name, value);
    }
  };

  const handleStatusToggle = () => {
    setThreadData(prevData => ({
      ...prevData,
      status: prevData.status === 'open' ? 'closed' : 'open'
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleSubmit = () => {
    let isValid = true;
    if (!isEditing) {
      isValid = validateField('title', threadData.title) && validateField('description', threadData.description);
    }

    if (isValid) {
      onSubmit(threadData);
      onClose();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle className={classes.DialogTitle}>{isEditing ? 'Thread Details' : 'Create Thread'}</DialogTitle>
      <DialogContent>
        {isEditing ? (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab
                label="Details"
                sx={{
                  color: tabValue === 0 ? 'Red' : 'black',
                  fontWeight: tabValue === 0 ? 'bold' : 'bold'
                }}
              />
              <Tab
                label="Messages"
                sx={{
                  color: tabValue === 1 ? 'Red' : 'black',
                  fontWeight: tabValue === 1 ? 'bold' : 'bold'
                }}
              />
            </Tabs>
          </Box>
        ) : null}

        <Box hidden={tabValue !== 0 && isEditing}>
          {!isEditing && (
            <>
              <TextField
                fullWidth
                margin="normal"
                name="title"
                label="Title"
                value={threadData.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.title}
                helperText={errors.title}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name="description"
                label="Description"
                multiline
                rows={4}
                value={threadData.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.description}
                helperText={errors.description}
                required
              />
            </>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={threadData.status === 'open'}
                onChange={handleStatusToggle}
                name="status"
                color="primary"
              />
            }
            label={`Thread is ${threadData.status}`}
          />
        </Box>

        {isEditing && toolData && (
          <Box hidden={tabValue !== 1}>
            <ThreadMessages threadId={toolData.id} threadStatus={threadData.status} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          {isEditing ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThreadDetailsModal;