import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ChevronLeft,
  Menu as MenuIcon
} from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import BuildIcon from '@mui/icons-material/Build';
import ForumIcon from '@mui/icons-material/Forum';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import makeApiRequest from '../utils/api';
import Logo from '../Logo.png'

const MIN_WIDTH = 60;
const MAX_WIDTH = 240;

const Sidebar = () => {
  const [width, setWidth] = useState(MAX_WIDTH);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sidebarRef = useRef(null);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setWidth(MAX_WIDTH);
    }
  }, [isMobile]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isResizing) return;

      const newWidth = e.clientX - sidebarRef.current.getBoundingClientRect().left;
      if (newWidth >= MIN_WIDTH && newWidth <= MAX_WIDTH) {
        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  const fetchUserDetails = async () => {
    try {
      const response = await makeApiRequest("api/users/me");
      setUserName(response.name);
      setUserEmail(response.email);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
    } else {
      setWidth(width === MAX_WIDTH ? MIN_WIDTH : MAX_WIDTH);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const isSelected = (path) => location.pathname === path;

  const menuItems = [
    { text: 'Subjects', icon: <PeopleIcon />, path: '/subjects' },
    { text: 'Users', icon: <BookIcon />, path: '/users' },
    { text: 'Tools', icon: <BuildIcon />, path: '/tools' },
    { text: 'Threads', icon: <ForumIcon />, path: '/threads' },
  ];

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    navigate('/');
  };

  const drawerContent = (
    <Box sx={{ 
      height: '100%', 
      backgroundColor: isMobile ? 'rgba(200, 220, 255, 0.3)' : 'inherit' 
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <img src={Logo} style={{width:"20px",height:"20px",cursor:"pointer"}}/>
        <IconButton onClick={handleDrawerToggle}>
          <ChevronLeft />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Avatar alt={userName} />
        <Box sx={{ ml: 2, display: isMobile || width > MIN_WIDTH ? 'block' : 'none' }}>
          <Typography variant="subtitle1">{userName}</Typography>
          <Typography variant="body2" color="text.secondary">{userEmail}</Typography>
        </Box>
      </Box>

      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => handleNavigate(item.path)}
            selected={isSelected(item.path)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {(isMobile || width > MIN_WIDTH) && <ListItemText primary={item.text} />}
          </ListItem>
        ))}
      </List>

      <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            {(isMobile || width > MIN_WIDTH) && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }} ref={sidebarRef}>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ position: 'fixed', top: 16, left: 16, zIndex: 1200 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {isMobile ? (
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': {
              width: MAX_WIDTH,
              backgroundColor: '#1976D2',
              color: "white"
            },
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: width,
            flexShrink: 0,
            position: 'relative',
            '& .MuiDrawer-paper': {
              width: width,
              boxSizing: 'border-box',
              overflowX: 'hidden',
              transition: isResizing ? 'none' : 'width 0.2s',
            },
          }}
        >
          {drawerContent}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: '5px',
              cursor: 'ew-resize',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              setIsResizing(true);
            }}
          />
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;