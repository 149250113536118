import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const UserModal = ({ open, onClose, onSubmit, userData, isEditing }) => {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [organization, setOrganization] = useState('');
    const [autoGeneratePassword, setAutoGeneratePassword] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [touched, setTouched] = useState({ name: false, email: false });

    useEffect(() => {
        if (userData && isEditing && open) {
            setName(userData.name || '');
            setEmail(userData.email || '');
            setRole(userData.role || '');
            setOrganization(userData.organization || '');
            setPassword('');
            setAutoGeneratePassword(false);
        } else if (open && !isEditing) {
            setName('');
            setEmail('');
            setRole('');
            setOrganization('');
            setPassword(generateRandomPassword());
            setAutoGeneratePassword(true);
        }
        setNameError('');
        setEmailError('');
    }, [userData, isEditing, open]);

    const generateRandomPassword = () => {
        return Math.random().toString(36).slice(-8);
    };

    const validateField = (field, value) => {
        let error = '';

        if (field === 'name') {
            if (!value.trim()) {
                error = 'Name is required';
            } else if (!/^[a-zA-Z][a-zA-Z\s_]+$/.test(value)) {
                error = 'Must start with a letter and can contain only letters, spaces, and underscores';
            } else if (value.length > 40) {
                error = 'Name must be less than or equal to 40 characters';
            }
        } else if (field === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value.trim()) {
                error = 'Email is required';
            } else if (!emailRegex.test(value)) {
                error = 'Invalid email format';
            }
        }

        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'name') {
            setName(value);
            if (touched.name) {
                setNameError(validateField('name', value));
            }
        } else if (name === 'email') {
            setEmail(value);
            if (touched.email) {
                setEmailError(validateField('email', value));
            }
        } else if (name === 'role') {
            setRole(value);
        } else if (name === 'organization') {
            setOrganization(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setTouched((prev) => ({ ...prev, [name]: true }));

        if (name === 'name') {
            setNameError(validateField('name', value));
        } else if (name === 'email') {
            setEmailError(validateField('email', value));
        }
    };

    const handleSubmit = () => {
        setTouched({
            name: true,
            email: true,
        });

        let valid = true;

        const nameErrorText = validateField('name', name);
        if (nameErrorText) {
            setNameError(nameErrorText);
            valid = false;
        }

        const emailErrorText = validateField('email', email);
        if (emailErrorText) {
            setEmailError(emailErrorText);
            valid = false;
        }

        if (valid) {
            const userDataToSubmit = {
                name,
                email,
                role,
                organization,
                password: autoGeneratePassword ? generateRandomPassword() : password,
            };

            onSubmit(userDataToSubmit);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className={classes.DialogTitle}>{isEditing ? 'Edit' : 'Add'} User</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    error={touched.name && !!nameError}
                    helperText={touched.name && nameError}
                    name="name"
                    required={!isEditing}
                    InputLabelProps={{
                        shrink: true,
                        required: true,
                        classes: { asterisk: classes.asterisk },
                    }}
                />
                <TextField
                    label="Organization"
                    variant="outlined"
                    value={organization}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    name="organization"
                />
                {!isEditing && (
                    <>
                        <TextField
                            label="Email Address"
                            variant="outlined"
                            value={email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            margin="normal"
                            error={touched.email && !!emailError}
                            helperText={touched.email && emailError}
                            name="email"
                            required
                            InputLabelProps={{
                                shrink: true,
                                required: true,
                                classes: { asterisk: classes.asterisk },
                            }}
                        />
                        <FormControl fullWidth margin="normal" variant="outlined">
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={role}
                                onChange={handleChange}
                                name="role"
                                label="Role" >
                                <MenuItem value="ROLE_USER">ROLE_USER</MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={autoGeneratePassword}
                                    onChange={() => setAutoGeneratePassword(!autoGeneratePassword)}
                                    color="primary"
                                />
                            }
                            label="Auto-generate password"
                        />
                        {!autoGeneratePassword && (
                            <TextField
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={password}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                required
                                name="password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >
                    {isEditing ? 'Save' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserModal;