import React, { useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  TablePagination,
  Link,
  Chip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from './styles';
import './tools-styles.css';
import ThreadDetailsModal from './ThreadDetailsModal';

const tableHeadings = [
  { id: 'title', label: 'Title' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'createdOn', label: 'Created On' },
  { id: 'status', label: 'Status' },
];

const ThreadsTable = ({ 
  threads, 
  loading, 
  onReadMore, 
  onUpdateStatus,
  onEdit,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleReadMore = (thread) => {
    if (onReadMore) {
      onReadMore(thread.id);
    } else {
      setSelectedThread(thread);
      setDetailsModalOpen(true);
    }
  };

  const handleEdit = (thread, event) => {
    event.stopPropagation();
    if (onEdit) {
      onEdit(thread.id, thread.status);
    }
  };

  const handleStatusToggle = (thread, event) => {
    event.stopPropagation();
    if (onUpdateStatus) {
      const newStatus = thread.status === 'open' ? 'closed' : 'open';
      onUpdateStatus(thread.id, newStatus);
    }
  };

  const handleModalClose = () => {
    setDetailsModalOpen(false);
    setSelectedThread(null);
  };

  const handleModalSubmit = (updatedThread) => {
    if (onUpdateStatus) {
      onUpdateStatus(updatedThread.id, updatedThread.status);
    }
    handleModalClose();
  };

  const loadingRows = (count) => {
    const rows = [];
    for (let i = 0; i < count; i++) {
      rows.push(
        <TableRow key={i}>
          {tableHeadings.map((_, index) => (
            <TableCell key={index} sx={{ padding: '16px' }}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      );
    }
    return rows;
  };

  const truncateText = (text, maxLength = 100) => {
    if (!text || text.length <= maxLength) return text;
    return (
      <span>
        {text.slice(0, maxLength)}...{' '}
        <Link
          component="button"
          variant="body2"
          onClick={(e) => {
            e.stopPropagation();
            handleReadMore({ description: text });
          }}
        >
          Read More
        </Link>
      </span>
    );
  };

  const paginatedThreads = threads.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {tableHeadings.map((header) => (
                <TableCell 
                  sx={{ padding: "16px", fontWeight: 'bold' }} 
                  key={header.id} 
                  className={classes.tableHeadCell}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              loadingRows(rowsPerPage)
            ) : (
              paginatedThreads.map((thread) => (
                <TableRow 
                  key={thread.id}
                  onClick={() => handleReadMore(thread)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell className='padding-16'>{truncateText(thread.title)}</TableCell>
                  <TableCell className='padding-16'>{thread.createdBy}</TableCell>
                  <TableCell className='padding-16'>
                    {new Date(thread.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell className='padding-16'>
                    <Chip 
                      label={thread.status}
                      color={thread.status === 'open' ? 'primary' : 'default'}
                      onClick={(e) => handleStatusToggle(thread, e)}
                    />
                    <IconButton onClick={(e) => handleEdit(thread, e)} style={{ marginLeft: '8px' }}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={threads.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      
      <ThreadDetailsModal
        open={detailsModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        toolData={selectedThread}
        isEditing={true}
      />
    </>
  );
};

export default ThreadsTable;