
import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { validateField, validateForm } from '../utils/validationUtils';
import useStyles from './styles';

const ToolModal = ({ open, onClose, onSubmit, toolData, isEditing }) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    url: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
    url: ''
  });

  const [touched, setTouched] = useState({
    name: false,
    description: false,
    url: false
  });

  useEffect(() => {
    if (toolData) {
      setFormData({
        name: toolData.name || '',
        description: toolData.description || '',
        url: toolData.url || ''
      });

      setErrors({
        name: '',
        description: '',
        url: ''
      });
      setTouched({
        name: false,
        description: false,
        url: false
      });
    } else {
      setFormData({
        name: '',
        description: '',
        url: ''
      });
    }
  }, [toolData]);

  const handleChange = (e) => {
    const { name, value, } = e.target;
    
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name.toUpperCase(), value)
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    setErrors(prev => ({
      ...prev,
      [name]: validateField(name.toUpperCase(), value)
    }));
  };

  const handleSubmit = () => {
    const { isValid, errors: formErrors } = validateForm(formData, ['NAME', 'DESCRIPTION', 'URL']);
    if (isValid) {
      onSubmit(formData);
      setFormData({ name: '', description: '', url: '' });
    } else {
      setErrors(formErrors);
      setTouched({
        name: true,
        description: true,
        url: true
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className={classes.DialogTitle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{isEditing ? 'Edit Tool' : 'Add Tool'}</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Tool Name"
          type="text"
          fullWidth
          value={formData.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
          variant="outlined"
        />
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.description && !!errors.description}
          helperText={touched.description && errors.description}
          variant="outlined"
          inputProps={{ maxLength: 2000 }}
        />
        <Typography variant="caption" color="textSecondary">
          {formData.description.length}/2000 characters
        </Typography>
        <TextField
          margin="dense"
          name="url"
          label="URL"
          type="url"
          fullWidth
          value={formData.url}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.url && !!errors.url}
          helperText={touched.url && errors.url}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {isEditing ? 'Save Changes' : 'Add Tool'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToolModal;