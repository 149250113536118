import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Alert, Breadcrumbs, Typography, Link,Tooltip  } from '@mui/material';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Sidebar from './sidebar';
import { useParams, useNavigate } from 'react-router-dom';
import makeApiRequest from '../utils/api';
import HomeIcon from '@mui/icons-material/Home';
import './mark-down-preview.css';
import useStyles from './styles';

const MarkDownPreview = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { topicId, articleId } = useParams();
    const [step, setStep] = useState(1);
    const [articleName, setArticleName] = useState('');
    const [description, setDescription] = useState('');
    const [stepName, setStepName] = useState('Step 1');
    const [markdownContent, setMarkdownContent] = useState('');
    const [stepsContent, setStepsContent] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [topicName, setTopicName] = useState('');
    const [subjectName, setSubjectName] = useState('');
    const [articleNameError, setArticleNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState('');
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (topicId) {
                    const topicResponse = await makeApiRequest(`api/topics/${topicId}`);
                    setTopicName(topicResponse.name);
                    if (topicResponse.subjectId) {
                        const subjectResponse = await makeApiRequest(`api/subjects/${topicResponse.subjectId}`);
                        setSubjectName(subjectResponse.name);
                    }
                }
                if (articleId) {
                    const articleResponse = await makeApiRequest(`api/articles/${articleId}`);
                    setArticleName(articleResponse.name);
                    setDescription(articleResponse.description);
                    setStepsContent(articleResponse.steps);
                    setStepName(articleResponse.steps[0]?.stepName || 'Step 1');
                    setMarkdownContent(articleResponse.steps[0]?.content || '');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [topicId, articleId, token]);

    const handleNextStep = () => {
        const currentStepContent = { position: step, step_name: stepName, content: markdownContent };
        const updatedStepsContent = [...stepsContent];
        updatedStepsContent[step - 1] = currentStepContent;

        setStepsContent(updatedStepsContent);
        setStep(step + 1);
        setStepName(`Step ${step + 1}`);
        setMarkdownContent(updatedStepsContent[step]?.content || '');
    };

    const handleBackStep = () => {
        if (step > 1) {
            setStep(step - 1);
            const previousStepContent = stepsContent[step - 2];
            setStepName(previousStepContent.step_name);
            setMarkdownContent(previousStepContent.content);
        }
    };

    const handleArticleNameChange = (e) => {
        const newValue = e.target.value;
        setArticleName(newValue);

        const namePattern = /^[a-zA-Z][a-zA-Z_ ]*$/; 
        if (newValue.trim() === '') {
            setArticleNameError('Article name is required.');
        } else if (!namePattern.test(newValue.trim())) {
            setArticleNameError('Article name must start with a character and can only contain letters, underscores, and spaces.');
        } else if (newValue.length > 40) {
            setArticleNameError('Article name must be 40 characters or less.');
        } else {
            setArticleNameError(''); 
        }
    };

    const handleDescriptionChange = (e) => {
        const newValue = e.target.value;
        setDescription(newValue);
        if (newValue.trim() !== '') {
            setDescriptionError('');
        }
    };

    const handleArticleNameBlur = () => {
        if (articleName.trim() === '') {
            setArticleNameError('Article name is required.');
        } else {
            setArticleNameError('');
        }
    };

    const handleDescriptionBlur = () => {
        if (description.trim() === '') {
            setDescriptionError('Description is required.');
        } else {
            setDescriptionError('');
        }
    };

    const validateFields = () => {
        let isValid = true;

        if (articleName.trim() === '') {
            setArticleNameError('Article name is required.');
            isValid = false;
        } else {
            setArticleNameError('');
        }

        if (description.trim() === '') {
            setDescriptionError('Description is required.');
            isValid = false;
        } else {
            setDescriptionError('');
        }

        return isValid;
    };

    const handleSave = async () => {
        const isValid = validateFields();
        if (!isValid) {
            return;
        }

        const currentStepContent = { position: step, step_name: stepName, content: markdownContent };
        const updatedStepsContent = [...stepsContent];
        updatedStepsContent[step - 1] = currentStepContent;

        const articleData = {
            name: articleName,
            description: description,
            topicId: topicId,
            steps: updatedStepsContent.map((content, index) => ({
                position: index + 1,
                stepName: content.step_name,
                content: content.content,
            })),
        };

        console.log(articleData)

        try {
            const apiUrl = articleId
                ? `/api/articles/${articleId}`
                : "/api/articles";

            const method = articleId ? 'PUT' : 'POST';

            await makeApiRequest(apiUrl, method, {}, articleData);

            setSnackbarMessage('Article saved successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

            setTimeout(() => {
                navigate(`/articles/${topicId}`);
            }, 3000);
        } catch (error) {
            console.error("Error saving article:", error);
            setSnackbarMessage('Error saving article. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleBreadcrumbClick = (path) => {
        navigate(path);
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return `${text.substring(0, maxLength)}...`;
    };

    const hasErrors = !!(articleNameError || descriptionError);

    return (
        <Box className={classes.root} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Sidebar />
            <Box
                sx={{
                    width: { xs: '100%', md: '80%' },
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    backgroundColor: '#ffffff',
                }} >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                    <Link
                        color="inherit"
                        onClick={() => handleBreadcrumbClick('/')}
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Home
                    </Link>
                    
                    {subjectName && (
                        <Tooltip title={subjectName}>
                            <Link
                                color="inherit"
                                onClick={() => handleBreadcrumbClick('/subjects')}
                                sx={{ cursor: 'pointer' }}
                            >
                                Subject - {truncateText(subjectName, 15)}
                            </Link>
                        </Tooltip>
                    )}
                    {topicName && (
                        <Link
                            color="inherit"
                            onClick={() => handleBreadcrumbClick(`/articles/${topicId}`)}
                            sx={{ cursor: 'pointer' }}
                        >
                        Topic - {topicName}
                        </Link>
                    )}
                    
                    <Typography color="text.primary">
                        {articleId ? articleName : 'New Article'}
                    </Typography>
                </Breadcrumbs>
                <Header
                    description={description}
                    setDescription={handleDescriptionChange}
                    articleName={articleName}
                    setArticleName={handleArticleNameChange}
                    stepName={stepName}
                    setStepName={setStepName}
                    step={step}
                    articleNameError={articleNameError}
                    descriptionError={descriptionError}
                    handleArticleNameBlur={handleArticleNameBlur}
                    handleDescriptionBlur={handleDescriptionBlur}
                />
                <Body
                    markdownContent={markdownContent}
                    setMarkdownContent={setMarkdownContent}
                />
                <Footer
                    step={step}
                    handleNextStep={handleNextStep}
                    handleBackStep={handleBackStep}
                    handleSave={handleSave}
                    hasErrors={hasErrors}

                />
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MarkDownPreview;